<template>
  <v-list-item @click="$emit('selectSite', site)">
    <v-list-item-content>
      <v-list-item-title>{{ site.site_name }}</v-list-item-title>
      <v-list-item-subtitle>{{ site.publish_status }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
    name: 'SiteListItem',
    props: ['site']
}
</script>