<template>
  <v-card>
    <v-card-title>{{ site.site_name }}</v-card-title>
    <v-card-subtitle>{{ site.publish_status }}</v-card-subtitle>
  </v-card>
</template>

<script>
export default {
    name: 'Site',
    props: ['site']
}
</script>