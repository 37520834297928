<template>
  <ExtendedView :tabs="tabs">
    <template slot="list">
      <ActionList
        :empty-text="$t('noSitesFound')"
        :call-to-actions="callToActions"
        :load-items-callback="getSites"
      >
        <template v-slot:item="{ item, index }">
          <SiteListItem
            v-if="item !== null"
            :key="item.uid"
            :site="item"
            @selectSite="selectSite"
          />
          <v-divider
            inset
            :key="index"
          />
        </template>
      </ActionList>
    </template>
    <template slot="profile">
      <SiteProfile :site="selectedSite" />
    </template>
  </ExtendedView>
</template>

<script>

  import ExtendedView from "@/components/common/ExtendedView"
  import ActionList from "@/components/common/list/ActionList"
  import SiteProfile from "@/components/sites/SiteProfile"
  import SiteListItem from "@/components/sites/SiteListItem"

  export default {
    name: 'Sites',
    components: {SiteProfile, ExtendedView, ActionList, SiteListItem},
    data: () => ({
      newSite: false,
      selectedSite: null
    }),
    watch: {
      sites() {
        this.newSite = false
      }
    },
    computed: {
      callToActions() {
        return [
          {
            title: this.$t('addSite'),
            callback: null
          }
        ]
      },
      tabs() {
        return [
          {
            title: this.$t('active'),
            key: 'active',
            badge: {
              color: 'green',
              content: 0
            }
          }
        ]
      },
      templates() {
        return this.$store.state.siteTemplates
      }
    },
    methods: {
      selectSite(site) {
        this.selectedSite = site
      },
      getSites() {
        return this.$store.dispatch('getSitesAndTemplates').then(data => {
          return data.sites
        })
      }
    },
    mounted() {
      this.$store.commit('updateAppBarTabs', this.tabs)
      this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
    }
  }
</script>
